import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, FieldError, FieldErrorsImpl, Merge, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ReplyIcon from '@mui/icons-material/Reply';
import ForwardOutlinedIcon from '@mui/icons-material/ForwardOutlined';
import {
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import './style.scss';
import { dropDownHelperFinancial, formatNumber, formatPortugueseNumber, parsePortugueseNumber } from './helper';
import { unitsFinancialsListSelector } from 'store/properties/selector';
import { useNavigate, useParams } from 'react-router-dom';
import { PropertiesReducerAction } from 'store/properties/slice';
import { PropertiesSagaActions } from 'store/properties/saga';
import { InstallmentDataType, UnitFinancialsDataType } from 'store/properties/types';
import { PlusAddIconBig } from 'ui-component/Icons';
import { CloseOutlined } from '@mui/icons-material';
import { SnackbarReducerAction } from 'store/snackbar/slice';

const FinancialTabs = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { id } = useParams();
    const schema = yup.object().shape({
        pre_launch_price: yup
            .string()
            .min(0, 'Pre launch Price must be positive value')
            .matches(/^[0-9.,]+$/, 'Pre launch Price must contain only digits')
            .required('Pre Launch Price is required'),
        closing_price: yup
            .string()
            .matches(/^[0-9.,]+$/, 'Closing Price must contain only digits')
            .test('is-greater', 'Closing Price should not be less than Pre Launch Price', function (value: any) {
                const { pre_launch_price } = this.parent;
                return !unitsFinancialsList?.status ? parsePortugueseNumber(value) >= parsePortugueseNumber(pre_launch_price) : true;
            }),
        commission_percentage: yup
            .number()
            .typeError('Commission Percentage must not be null')
            .min(0, 'Commission Percentage must be positive value')
            .nullable(),
        paid_value: yup.string().matches(/^[0-9.,]+$/, 'Paid Value must contain only digits'),
        deed_amount: yup.string().matches(/^[0-9.,]+$/, 'Deed Amount must contain only digits'),
        reservation_amount: yup.string().matches(/^[0-9.,]+$/, 'Reservation Amount must contain only digits'),
        installments: yup
            .array()
            .ensure()
            .of(
                yup.object().shape({
                    amount: yup.string().matches(/^[0-9.,]+$/, 'Value must contain only digits')
                })
            )
            .nullable()
    });
    const unitsFinancialsList = useSelector(unitsFinancialsListSelector);
    const installmentInitialValues = {
        amount: 0,
        date: new Date(),
        payment_status: 'Pending'
    };
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
        control,
        setValue
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues:
            unitsFinancialsList && ({ ...unitsFinancialsList, installments: [installmentInitialValues] } as UnitFinancialsDataType)
    });

    const { fields, append, remove } = useFieldArray({
        name: 'installments',
        control
    });

    const { installments: installmentError }: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | any = errors;

    const onSubmit = async (values: { [key: string]: any }) => {
        const data = {
            pre_launch_price: parsePortugueseNumber(values?.pre_launch_price),
            closing_price: parsePortugueseNumber(values?.closing_price),
            commission_percentage: Number(values?.commission_percentage),
            commission_iva: Number(values?.commission_iva),
            paid_value: parsePortugueseNumber(values?.paid_value),
            pending_value: Number(parsePortugueseNumber(values?.closing_price)) - Number(parsePortugueseNumber(values?.paid_value)),
            deed_amount: {
                amount: parsePortugueseNumber(values?.deed_amount),
                date: values?.deed_date,
                payment_status: values?.deed_status,
                title: values?.deed_title
            },
            reservation_amount: {
                title: values?.title,
                amount: parsePortugueseNumber(values?.reservation_amount),
                date: values?.reservation_date,
                payment_status: values?.reservation_status
            },
            installments: values?.installments?.map((installment: { [key: string]: any }) => ({
                ...installment,
                amount: parsePortugueseNumber(installment.amount)
            })),
            unit_id: values?.unit_id ?? id,
            _id: values?._id,
            notes: values?.notes
        };
        const totalPaid =
            data?.installments?.reduce((acc: number, installment: { [key: string]: any }) => {
                return installment?.payment_status?.includes('Paid') ? acc + installment.amount : acc;
            }, 0) +
            (values?.reservation_status === 'Paid' ? data?.reservation_amount?.amount : 0) +
            (values?.deed_status === 'Paid' ? data?.deed_amount?.amount : 0);

        const isDeedInvalid = parsePortugueseNumber(values?.deed_amount) === 0 && values?.deed_status === 'Paid';
        const isReservationInvalid = parsePortugueseNumber(values?.reservation_amount) === 0 && values?.reservation_status === 'Paid';
        const isInstallmentsInvalid = data?.installments?.some((installment: { [key: string]: any }) => {
            return installment?.amount === 0 && installment?.payment_status === 'Paid';
        });
        if (isDeedInvalid || isReservationInvalid || isInstallmentsInvalid) {
            dispatch(
                SnackbarReducerAction.openSnackbar({
                    open: true,
                    message: "Reservation/Deed/Installment can't be marked as Paid with amount 0"
                })
            );
        } else if (totalPaid > data?.closing_price) {
            dispatch(
                SnackbarReducerAction.openSnackbar({
                    open: true,
                    message: 'The total paid amount should not be higher than the closing price'
                })
            );
        } else {
            await dispatch(PropertiesSagaActions.createCalculationsForUnit(data));
        }
    };

    useEffect(() => {
        if (id) {
            dispatch(PropertiesSagaActions.fetchUnitFinancialsById(id));
        }
    }, [dispatch]);

    const handleBack = () => {
        navigate(-1);
        dispatch(PropertiesReducerAction.setUnitFinancialsList({}));
    };

    useEffect(() => {
        if (unitsFinancialsList) {
            reset({
                ...unitsFinancialsList,
                pending_value: formatNumber(unitsFinancialsList?.pending_value),
                pre_launch_price: formatNumber(unitsFinancialsList.pre_launch_price),
                closing_price: formatNumber(unitsFinancialsList.closing_price) || 0,
                paid_value: formatNumber(unitsFinancialsList.paid_value) || 0,
                net_value: unitsFinancialsList?.net_value || 0,
                commission_total: unitsFinancialsList?.commission_total,
                commission_percentage: unitsFinancialsList.commission_percentage || 0,
                deed_amount: formatNumber(unitsFinancialsList?.deed_amount?.amount) ?? 0,
                deed_date: unitsFinancialsList?.deed_amount?.date ?? new Date(),
                deed_status: unitsFinancialsList?.deed_amount?.payment_status,
                deed_title: unitsFinancialsList?.deed_amount?.title,
                reservation_amount: formatNumber(unitsFinancialsList?.reservation_amount?.amount) ?? 0,
                title: unitsFinancialsList?.reservation_amount?.title ?? '',
                reservation_date: unitsFinancialsList?.reservation_amount?.date ?? new Date(),
                reservation_status: unitsFinancialsList?.reservation_amount?.payment_status,
                commission_iva: unitsFinancialsList?.commission_iva,
                installments:
                    unitsFinancialsList?.installments?.length > 0
                        ? [
                              ...unitsFinancialsList?.installments?.map((installment: InstallmentDataType) => ({
                                  ...installment,
                                  amount: formatNumber(installment.amount)
                              }))
                          ]
                        : [installmentInitialValues]
            });
        }
    }, [unitsFinancialsList]);

    const watchFieldArray = watch('installments');
    const controlledFields = fields?.map((field, index) => {
        return {
            ...field,
            ...(watchFieldArray?.length && watchFieldArray[index])
        };
    });

    const comissionTotal =
        (watch('closing_price') && parsePortugueseNumber(watch('closing_price'))) * (watch('commission_percentage') / 100);
    const comissionWithIVA = comissionTotal + (comissionTotal * 23) / 100;
    const valueNet = useMemo(
        () =>
            parsePortugueseNumber(watch('pre_launch_price')) > parsePortugueseNumber(watch('closing_price'))
                ? watch('pre_launch_price') && parsePortugueseNumber(watch('pre_launch_price'))
                : (watch('closing_price') && parsePortugueseNumber(watch('closing_price'))) - comissionWithIVA,
        [watch('pre_launch_price'), watch('closing_price')]
    );

    const installmentsTotal =
        watch('installments')?.reduce((acc: number, installment: { [key: string]: any }, index: number) => {
            return installment?.payment_status === 'Paid' ? acc + Number(parsePortugueseNumber(installment?.amount)) : acc;
        }, 0) ?? 0;
    const reservationAmount = useMemo(() => {
        return watch('reservation_status') === 'Paid' ? parsePortugueseNumber(watch('reservation_amount')) : 0;
    }, [watch('reservation_status'), watch('reservation_amount')]);
    const deedAmount = useMemo(() => {
        return watch('deed_status') === 'Paid' ? parsePortugueseNumber(watch('deed_amount')) : 0;
    }, [watch('deed_status'), watch('deed_amount')]);
    const paidValue = installmentsTotal + reservationAmount + deedAmount;

    const pendingValue = !Boolean(errors?.closing_price)
        ? (watch('closing_price') && parsePortugueseNumber(watch('closing_price'))) - paidValue
        : watch('pending_value');

    return (
        <form>
            <Grid container className="input-property-forms financial-forms" xl={12} lg={12}>
                <Grid
                    item
                    marginBottom={'20px'}
                    paddingRight={{ xs: '0px', md: '10px' }}
                    className="input-property-forms"
                    xs={12}
                    md={3}
                    lg={3}
                >
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-pre-launch-price">Pre Launch Price</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-pre-launch-price"
                            sx={{ borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                            label="Pre Launch Price"
                            {...register('pre_launch_price', {
                                onChange: (e: any) => {
                                    const rawValue = e.target.value.replace(/\./g, '');
                                    setValue('pre_launch_price', formatPortugueseNumber(rawValue), { shouldValidate: true });
                                }
                            })}
                        />
                        <FormHelperText sx={{ color: 'red' }}>
                            {errors?.pre_launch_price && errors?.pre_launch_price?.message?.toString()}
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid
                    item
                    marginBottom={'20px'}
                    paddingRight={{ xs: '0px', md: '10px' }}
                    className="input-property-forms"
                    xs={12}
                    md={3}
                    lg={3}
                >
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-closing-price">Closing Price</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-closing-price"
                            sx={{ borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                            label="Closing Price"
                            disabled={unitsFinancialsList?.status}
                            {...register('closing_price', {
                                onChange: (e: any) => {
                                    const rawValue = e.target.value.replace(/\./g, '');
                                    setValue('closing_price', formatPortugueseNumber(rawValue), { shouldValidate: true });
                                }
                            })}
                        />
                        <FormHelperText sx={{ color: 'red' }}>
                            {errors?.closing_price && errors?.closing_price?.message?.toString()}
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid
                    item
                    marginBottom={'20px'}
                    paddingRight={{ xs: '0px', md: '10px' }}
                    className="input-property-forms"
                    xs={12}
                    md={3}
                    lg={3}
                >
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-commission-percentage">Comission Percentage</InputLabel>
                        <OutlinedInput
                            fullWidth
                            id={'outlined-adornment-commission-percentage'}
                            sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                            label="Commission Percentage"
                            type="number"
                            disabled={unitsFinancialsList?.status}
                            inputProps={{
                                step: 'any'
                            }}
                            defaultValue={watch('commission_percentage') ?? 0}
                            {...register('commission_percentage')}
                            startAdornment={<InputAdornment position="start">%</InputAdornment>}
                        />
                        <FormHelperText sx={{ color: 'red' }}>
                            {errors?.commission_percentage && errors?.commission_percentage?.message?.toString()}
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid
                    item
                    marginBottom={'20px'}
                    paddingRight={{ xs: '0px', md: '10px' }}
                    className="input-property-forms"
                    xs={12}
                    md={3}
                    lg={3}
                >
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-commission-total">Comission Total</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-commission-total"
                            sx={{ borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                            label="Comission Total"
                            readOnly
                            disabled={unitsFinancialsList?.status}
                            value={formatNumber(comissionTotal)}
                            {...register('commission_total')}
                        />
                    </FormControl>
                </Grid>
                <Grid
                    item
                    marginBottom={'20px'}
                    paddingRight={{ xs: '0px', md: '10px' }}
                    className="input-property-forms"
                    xs={12}
                    md={3}
                    lg={3}
                >
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-vat-comission">Comission + 23% IVA</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-vat-comission"
                            sx={{ borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                            label="Comission + 23% IVA"
                            readOnly
                            disabled={unitsFinancialsList?.status}
                            value={formatNumber(comissionWithIVA)}
                            {...register('commission_iva')}
                        />
                    </FormControl>
                </Grid>
                <Grid
                    item
                    marginBottom={'20px'}
                    paddingRight={{ xs: '0px', md: '10px' }}
                    className="input-property-forms"
                    xs={12}
                    md={3}
                    lg={3}
                >
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-net-value">Value Net</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-net-value"
                            sx={{ borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                            label="Value net"
                            readOnly
                            disabled={unitsFinancialsList?.status}
                            value={formatNumber(valueNet)}
                            {...register('net_value')}
                        />
                    </FormControl>
                </Grid>
                <Grid
                    item
                    marginBottom={'20px'}
                    paddingRight={{ xs: '0px', md: '10px' }}
                    className="input-property-forms"
                    xs={12}
                    md={3}
                    lg={3}
                >
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-paid-value">Paid Value</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-paid-value"
                            sx={{ borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                            label="Paid Value"
                            readOnly
                            value={formatNumber(paidValue)}
                            disabled={unitsFinancialsList?.status}
                            {...register('paid_value')}
                        />
                        <FormHelperText sx={{ color: 'red' }}>
                            {errors?.paid_value && errors?.paid_value?.message?.toString()}
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid
                    item
                    marginBottom={'20px'}
                    paddingRight={{ xs: '0px', md: '10px' }}
                    className="input-property-forms"
                    xs={12}
                    md={3}
                    lg={3}
                >
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-pending-value">Pending Value</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-pending-value"
                            sx={{ borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                            label="Pending Value"
                            readOnly
                            disabled={unitsFinancialsList?.status}
                            value={formatNumber(pendingValue)}
                            {...register('pending_value')}
                        />
                    </FormControl>
                </Grid>
                <Grid
                    item
                    marginBottom={'20px'}
                    paddingRight={{ xs: '0px', md: '10px' }}
                    className="input-property-forms"
                    xs={12}
                    md={3}
                    lg={3}
                >
                    <TextField
                        InputLabelProps={{ shrink: !!watch('title') }}
                        fullWidth
                        disabled={unitsFinancialsList?.status}
                        sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                        label="Reservation Title"
                        {...register('title')}
                    />
                </Grid>
                <Grid
                    item
                    marginBottom={'20px'}
                    paddingRight={{ xs: '0px', md: '10px' }}
                    className="input-property-forms"
                    xs={12}
                    md={3}
                    lg={3}
                >
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-reservation-amount">Reservation Amount</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-reservation-amount"
                            sx={{ borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                            label="Reservation Amount"
                            disabled={unitsFinancialsList?.status}
                            value={watch('reservation_amount')}
                            {...register('reservation_amount', {
                                onChange: (e: any) => {
                                    const rawValue = e.target.value.replace(/\./g, '');
                                    setValue('reservation_amount', formatPortugueseNumber(rawValue), { shouldValidate: true });
                                }
                            })}
                        />
                        <FormHelperText sx={{ color: 'red' }}>
                            {errors?.reservation_amount && errors?.reservation_amount?.message?.toString()}
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid
                    item
                    marginBottom={'20px'}
                    paddingRight={{ xs: '0px', md: '10px' }}
                    className="input-property-forms"
                    xs={12}
                    md={3}
                    lg={3}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Reservation Date"
                            reduceAnimations
                            sx={{
                                width: '100%',
                                borderRadius: '12px',
                                '.MuiInputBase-input': { fontWeight: '600' }
                            }}
                            disabled={unitsFinancialsList?.status}
                            {...register('reservation_date')}
                            value={dayjs(watch('reservation_date')) ?? dayjs(new Date())}
                            format="DD/MM/YYYY"
                            onChange={(newValue: any) => {
                                newValue && setValue('reservation_date', newValue?.toDate()?.toISOString(), { shouldValidate: true });
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid
                    item
                    marginBottom={'20px'}
                    paddingRight={{ xs: '0px', md: '10px' }}
                    className="input-property-forms"
                    xs={12}
                    md={3}
                    lg={3}
                >
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-reservation-status">Status</InputLabel>
                        <Controller
                            control={control}
                            name="reservation_status"
                            render={({ field: { value } }) => {
                                return (
                                    <Select
                                        key={value}
                                        labelId={value}
                                        value={value}
                                        label="Status"
                                        disabled={unitsFinancialsList?.status}
                                        defaultValue={dropDownHelperFinancial[0]?.value}
                                        sx={{
                                            width: '100%',
                                            borderRadius: '12px',
                                            '.MuiInputBase-input': { fontWeight: '600' }
                                        }}
                                        {...register('reservation_status')}
                                    >
                                        {dropDownHelperFinancial.map((option) => (
                                            <MenuItem value={option.value}>
                                                <div className="menu-option">{option.label}</div>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
                {controlledFields?.map((field, index) => (
                    <Grid container key={field?._id}>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={3}
                            lg={3}
                        >
                            <TextField
                                InputLabelProps={{ shrink: !!watch(`installments.${index}.title`) }}
                                fullWidth
                                disabled={unitsFinancialsList?.status}
                                sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                label="Installment Title"
                                {...register(`installments.${index}.title`)}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={3}
                            lg={3}
                        >
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-installment">Installment Amount</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-installment"
                                    sx={{ borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                                    startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                    label="Installment Amount"
                                    disabled={unitsFinancialsList?.status}
                                    value={formatNumber(watch(`installments.${index}.amount`))}
                                    {...register(`installments.${index}.amount`, {
                                        onChange: (e: any) => {
                                            const rawValue = e.target.value.replace(/\./g, '');
                                            setValue(`installments.${index}.amount`, formatPortugueseNumber(rawValue), {
                                                shouldValidate: true
                                            });
                                        }
                                    })}
                                />
                                <FormHelperText sx={{ color: 'red' }}>
                                    {installmentError && installmentError?.[index]?.amount?.message.toString()}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={12}
                            md={3}
                            lg={3}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Date"
                                    reduceAnimations
                                    disabled={unitsFinancialsList?.status}
                                    sx={{
                                        width: '100%',
                                        borderRadius: '12px',
                                        '.MuiInputBase-input': { fontWeight: '600' }
                                    }}
                                    value={dayjs(watch(`installments.${index}.date`)) ?? dayjs(new Date())}
                                    {...register(`installments.${index}.date`)}
                                    format="DD/MM/YYYY"
                                    onChange={(newValue: any) => {
                                        newValue &&
                                            setValue(`installments.${index}.date`, newValue?.toDate()?.toString(), {
                                                shouldValidate: true
                                            });
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid
                            item
                            marginBottom={'20px'}
                            paddingRight={{ xs: '0px', md: '10px' }}
                            className="input-property-forms"
                            xs={11}
                            md={2.5}
                            lg={2.5}
                        >
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-paymnent-status">Status</InputLabel>
                                <Controller
                                    control={control}
                                    name={`installments.${index}.payment_status`}
                                    render={({ field: { value } }) => {
                                        return (
                                            <Select
                                                key={value}
                                                labelId={value}
                                                value={value}
                                                label="Status"
                                                disabled={unitsFinancialsList?.status}
                                                defaultValue={dropDownHelperFinancial[0]?.value}
                                                sx={{
                                                    width: '100%',
                                                    borderRadius: '12px',
                                                    '.MuiInputBase-input': { fontWeight: '600' }
                                                }}
                                                {...register(`installments.${index}.payment_status`)}
                                            >
                                                {dropDownHelperFinancial.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        <div className="menu-option">{option.label}</div>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid
                            item
                            xs
                            padding={{ xs: '0px', md: '10px' }}
                            className="plus-icon"
                            onClick={() => {
                                if (unitsFinancialsList?.status) return;
                                return index === 0
                                    ? controlledFields?.length === 5
                                        ? dispatch(
                                              SnackbarReducerAction.openSnackbar({
                                                  open: true,
                                                  message: 'You can add only 5 installments'
                                              })
                                          )
                                        : append({ ...installmentInitialValues })
                                    : remove(index);
                            }}
                        >
                            {index === 0 ? <PlusAddIconBig /> : <CloseOutlined />}
                        </Grid>
                    </Grid>
                ))}
                <Grid
                    item
                    marginBottom={'20px'}
                    paddingRight={{ xs: '0px', md: '10px' }}
                    className="input-property-forms"
                    xs={12}
                    md={3}
                    lg={3}
                >
                    <TextField
                        InputLabelProps={{ shrink: !!watch('deed_title') }}
                        fullWidth
                        sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                        label="Deed Title"
                        disabled={unitsFinancialsList?.status}
                        {...register('deed_title')}
                    />
                </Grid>
                <Grid
                    item
                    marginBottom={'20px'}
                    paddingRight={{ xs: '0px', md: '10px' }}
                    className="input-property-forms"
                    xs={12}
                    md={3}
                    lg={3}
                >
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-deed-amount">Deed Amount</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-deed-amount"
                            sx={{ borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                            label="Deed Amount"
                            disabled={unitsFinancialsList?.status}
                            {...register('deed_amount', {
                                onChange: (e: any) => {
                                    const rawValue = e.target.value.replace(/\./g, '');
                                    setValue('deed_amount', formatPortugueseNumber(rawValue), { shouldValidate: true });
                                }
                            })}
                        />
                        <FormHelperText sx={{ color: 'red' }}>
                            {errors?.deed_amount && errors?.deed_amount?.message?.toString()}
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid
                    item
                    marginBottom={'20px'}
                    paddingRight={{ xs: '0px', md: '10px' }}
                    className="input-property-forms"
                    xs={12}
                    md={3}
                    lg={3}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Deed Date"
                            reduceAnimations
                            sx={{
                                width: '100%',
                                borderRadius: '12px',
                                '.MuiInputBase-input': { fontWeight: '600' }
                            }}
                            disabled={unitsFinancialsList?.status}
                            {...register('deed_date')}
                            value={dayjs(watch('deed_date')) ?? dayjs(new Date())}
                            format="DD/MM/YYYY"
                            onChange={(newValue: any) => {
                                newValue && setValue('deed_date', newValue?.toDate()?.toISOString(), { shouldValidate: true });
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid
                    item
                    marginBottom={'20px'}
                    paddingRight={{ xs: '0px', md: '10px' }}
                    className="input-property-forms"
                    xs={12}
                    md={3}
                    lg={3}
                >
                    <FormControl sx={{ width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-deed-status">Deed Status</InputLabel>
                        <Controller
                            control={control}
                            name="deed_status"
                            render={({ field: { value } }) => {
                                return (
                                    <Select
                                        key={value}
                                        labelId={value}
                                        value={value}
                                        disabled={unitsFinancialsList?.status}
                                        label="Deed status"
                                        defaultValue={dropDownHelperFinancial[0]?.value}
                                        sx={{
                                            width: '100%',
                                            borderRadius: '12px',
                                            '.MuiInputBase-input': { fontWeight: '600' }
                                        }}
                                        {...register('deed_status')}
                                    >
                                        {dropDownHelperFinancial.map((option) => (
                                            <MenuItem value={option.value}>
                                                <div className="menu-option">{option.label}</div>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item marginBottom={'20px'} paddingRight={{ xs: '0px', md: '10px' }} className="input-property-forms" xs={12}>
                    <TextField
                        InputLabelProps={{ shrink: !!watch('notes') }}
                        fullWidth
                        sx={{ width: '100%', borderRadius: '12px', '.MuiInputBase-input': { fontWeight: '600' } }}
                        label="Comission Notes"
                        {...register('notes')}
                    />
                </Grid>
                <Grid marginY={'10px'} display={'flex'} gap={'20px'}>
                    <Button variant="outlined" startIcon={<ReplyIcon className="back-icon" />} onClick={handleBack}>
                        Back to Property
                    </Button>
                    <Button
                        variant="outlined"
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                        startIcon={<ForwardOutlinedIcon className="forward-icon" />}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default FinancialTabs;
