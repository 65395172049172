import { createSlice } from '@reduxjs/toolkit';
import {
    PropertiesDataType,
    PropertiesDetailsType,
    propertyUnitsListDataType,
    UnitFinancialsDataType,
    UnitModificationDataType
} from './types';

export const initialState: PropertiesDetailsType = {
    propertyList: [],
    isDeleteModalOpen: false,
    isDeleteUnitModalOpen: false,
    isLoading: false,
    isPropertyGalleryLoading: false,
    isAddPropertyLoading: false,
    selectedProperty: {} as PropertiesDataType,
    propertyUnitsList: [],
    isPropertyunitsListLoading: false,
    propertyUpdatesList: [],
    propertyDocumentsList: [],
    propertyGalleryList: [],
    propertyEditUnitsDetails: [],
    propertyDetails: {} as PropertiesDataType,
    propertyDetailGalleryImages: [],
    selectedPropertyUnit: {} as propertyUnitsListDataType,
    isAddPropertyUnitLoading: false,
    unitsFinancialsList: [],
    unitsModificationsList: [],
    unitFinancialsList: {} as UnitFinancialsDataType,
    selectedUnitModification: {} as UnitModificationDataType,
    isPropertyUpdatesListLoading: false,
    clientsList: [],
    page: 1,
    pageSize: 12,
    total: 0,
    sortBy: 'createdAt',
    sortOrder: 'desc',
    search: '',
    unitsPage: 1,
    unitsPageSize: 12,
    unitsTotal: 0,
    unitsSortBy: 'unit_name',
    unitsSortOrder: 'asc',
    unitsSearch: '',
    techniciansList: [],
    fiscalRepresentativesList: [],
    legalRepresentativesList: [],
    architectsList: []
};

const properties = createSlice({
    name: 'propertiesState',
    initialState,
    reducers: {
        setPropertiesDetails(state, action) {
            state.propertyList = action.payload.data;
        },
        setToggleDeleteModal(state, action) {
            state.isDeleteModalOpen = action.payload;
        },
        setToggleDeleteUnitModal(state, action) {
            state.isDeleteUnitModalOpen = action.payload;
        },
        setSelectedProperty(state, action) {
            state.selectedProperty = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setIsPropertyGalleryLoading(state, action) {
            state.isPropertyGalleryLoading = action.payload;
        },
        setIsAddPropertyLoading(state, action) {
            state.isAddPropertyLoading = action.payload;
        },
        setPropertyUnitsDetails(state, action) {
            state.propertyUnitsList = action.payload.data;
        },
        setPropertyUnitsListLoading(state, action) {
            state.isPropertyunitsListLoading = action.payload;
        },
        setPropertyUpdatesDetails(state, action) {
            state.propertyUpdatesList = action.payload.data;
        },
        setPropertyDocumentsList(state, action) {
            state.propertyDocumentsList = action.payload.data;
        },
        setPropertyGalleryList(state, action) {
            state.propertyGalleryList = action.payload;
        },
        setPropertyEditUnitsDetails(state, action) {
            state.propertyEditUnitsDetails = action.payload;
        },
        setPropertyDetails(state, action) {
            state.propertyDetails = action.payload;
        },
        setPropertyDetailGalleryImages(state, action) {
            state.propertyDetailGalleryImages = action.payload;
        },
        setSelectedPropertyUnit(state, action) {
            state.selectedPropertyUnit = action.payload;
        },
        setIsAddPropertyUnitLoading(state, action) {
            state.isAddPropertyUnitLoading = action.payload;
        },
        setIsPropertyUpdatesListLoading(state, action) {
            state.isPropertyUpdatesListLoading = action.payload;
        },
        setUnitsFinancialsList(state, action) {
            state.unitsFinancialsList = action.payload;
        },
        setUnitModificationsList(state, action) {
            state.unitsModificationsList = action.payload;
        },
        setSelectedUnitModification(state, action) {
            state.selectedUnitModification = action.payload;
        },
        setPage(state, action) {
            state.page = action.payload;
        },
        setPageSize(state, action) {
            state.pageSize = action.payload;
        },
        setTotal(state, action) {
            state.total = action.payload;
        },
        setSortBy(state, action) {
            state.sortBy = action.payload;
        },
        setSortOrder(state, action) {
            state.sortOrder = action.payload;
        },
        setSearch(state, action) {
            state.search = action.payload;
        },
        setUnitsPage(state, action) {
            state.unitsPage = action.payload;
        },
        setUnitsPageSize(state, action) {
            state.unitsPageSize = action.payload;
        },
        setUnitsSortOrder(state, action) {
            state.unitsSortOrder = action.payload;
        },
        setUnitsTotal(state, action) {
            state.unitsTotal = action.payload;
        },
        setUnitsSortBy(state, action) {
            state.unitsSortBy = action.payload;
        },
        setUnitsSearch(state, action) {
            state.unitsSearch = action.payload;
        },
        setUnitFinancialsList(state, action) {
            state.unitFinancialsList = action.payload;
        },
        setClientsList(state, action) {
            state.clientsList = action.payload;
        },
        setTechniciansList(state, action) {
            state.techniciansList = action.payload;
        },
        setFiscalRepresentativesList(state, action) {
            state.fiscalRepresentativesList = action.payload;
        },
        setLegalRepresentativesList(state, action) {
            state.legalRepresentativesList = action.payload;
        },
        setArchitectsList(state, action) {
            state.architectsList = action.payload;
        }
    }
});

export const { actions: PropertiesReducerAction, name: PropertiesReducerName, reducer: PropertiesReducer } = properties;
