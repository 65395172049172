import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import SubHeaderComponent from 'ui-component/SubHeaderComponent';
import NewDocument from '../../Components/DocumentsForm/NewDocument';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { AgenciesSaga, AgenciesSagaName } from 'store/agencies/saga';
import { AgenciesReducer, AgenciesReducerName } from 'store/agencies/slice';
import { PropertiesReducer, PropertiesReducerName } from 'store/properties/slice';
import { PropertiesSaga, PropertiesSagaName } from 'store/properties/saga';
import { DocumentsReducer, DocumentsReducerName } from 'store/documents/slice';
import { DocumentsSaga, DocumentsSagaName } from 'store/documents/saga';
import { documentFormIsLoadingSelector } from 'store/documents/selector';
import Loader from 'ui-component/Loader';
import 'themes/customStyle.scss';

// ==============================|| DOCUMENTS ||============================== //

const NewDocumentsForm = ({ withoutSubHeader = false }: { withoutSubHeader: boolean }) => {
    const { id } = useParams();
    useInjectReducer({
        reducer: DocumentsReducer,
        key: DocumentsReducerName
    });

    useInjectSaga({
        saga: DocumentsSaga,
        key: DocumentsSagaName
    });
    useInjectSaga({
        saga: AgenciesSaga,
        key: AgenciesSagaName
    });

    useInjectReducer({
        reducer: AgenciesReducer,
        key: AgenciesReducerName
    });
    useInjectReducer({
        reducer: PropertiesReducer,
        key: PropertiesReducerName
    });
    useInjectSaga({
        saga: PropertiesSaga,
        key: PropertiesSagaName
    });

    const isLoading = useSelector(documentFormIsLoadingSelector);

    if (isLoading) return <Loader backdropOpen />;

    return (
        <Grid container className="component-container">
            {!withoutSubHeader && (
                <SubHeaderComponent
                    module="Documents"
                    customPathEndPointName={'Document'}
                    title="Account Profile"
                    homeIcon
                    subModule={[id ? 'Edit' : 'Add']}
                />
            )}
            <Grid
                item
                className="component-sub-container"
                xs={12}
                padding={withoutSubHeader ? '0px' : '10px 20px 20px 20px'}
                borderRadius={'0px 0px 7px 7px'}
            >
                <Grid className="component-sub-box client-form" borderRadius={3}>
                    <NewDocument />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default NewDocumentsForm;
